@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
	scroll-behavior: smooth;
	font-family: 'Roboto', sans-serif;
}
.navbar {
	background-color: #000;
}
.email-links span a {
	color: #000;
}
.card {
	width: 100%;
	padding: 10px;
	/* border-radius: 20px; */
	background: #fff;
	/* border: none; */
	position: relative;
}
@media only screen and (min-width: 768px) {
	.otp-img {
		float: right !important;
	}
}

.mobile-text {
	font-size: 15px;
	font-weight: 600;
}
#verify-email-form input[type='text'] {
	font-size: 30px;
	text-align: center;
}
/* #verify-email-form .form-control{

} */
.form-control {
	margin-right: 12px;
	/* width: 15%; */
	background-color: #eee;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #ff8880;
	outline: 0;
	box-shadow: none;
}
.email-subject {
	overflow-y: scroll;
	max-height: 300px;
}
.cursor {
	cursor: pointer;
}
.btn-custom {
	background-color: #0a053d;
	border: none;
	color: white;
	padding: 6px 32px;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
}
.btn-custom-2 {
	background-color: #0a053d;
	border: none;
	color: white;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
	font-size: 12px;
	line-height: 1;
}
.settings-links .cancel {
	color: #343a40;
}
.card .cursor {
	color: #0a053d;
	border-bottom: 1px solid;
}
.btn-custom:hover {
	color: #fff;
}
.btn-more {
	color: #0a053d;
	font-weight: 800;
}
.view-details {
	font-size: 12px;
}
.email-to-div,
.email-cc-div,
.email-to-bcc-div {
	margin-left: -15px;
	margin-top: 0px;
	margin-bottom: -10px;
}
.email-to,
.email-cc {
	font-size: 14px;
}
.to-email-text {
	margin-bottom: 7px;
	font-size: 12px;
}
@media only screen and (min-width: 768px) {
	section {
		display: block;
	}
}
input[type='button'] {
	outline: none !important;
	box-shadow: none !important;
}
.btn-custom:focus {
	border: none;
	outline: none;
}
@media only screen and (max-width: 767px) {
	.gif-logo {
		display: none !important;
	}
	.form-control {
		width: 100%;
	}
	.d-xs-none {
		display: none;
	}
	.text-xs-center {
		text-align: center !important;
	}
}

@media only screen and (max-width: 992px) {
	.drowpMenu{
		position: absolute;
		right: 0;
		z-index: 20;
	}
}


.dropdown-menu.show {
	display: block !important;
}

.py-6 {
	padding-top: 6em !important;
}
.ml-30 {
	margin-left: 30%;
}
mt-5 {
	margin-top: 5rem;
}
.nav-tabs {
	border-bottom: none;
}
.nav-tabs .nav-item {
	padding: 2px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #fff;
	background-color: #000;
	border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link {
	color: #000;
	background: lightgrey;
	border-radius: 15px 15px 0px 0px;
}
.tab-content {
	background-color: #efefef;
	margin-top: 5px;
}
.profile {
	width: 36px;
	margin: 6px 5px;
	height: 36px;
	border-radius: 100%;
	max-width: 36px;
	line-height: 36px;
	background-color: #ccc;
	padding: 0;
	text-align: center;
	color: #000;
	font-weight: 400;
}
.listHeading {
	font-size: 13px;
	font-weight: 300;
	line-height: 18px;
}
.listsubHeading {
	font-size: 11px;
	font-weight: 300;
	line-height: 15px;
}
.timetext {
	font-size: 11px;
	font-weight: 400;
}
.pt-10 {
	padding-top: 10px;
}
.pb-10 {
	padding-bottom: 10px;
}
.optdiv {
	width: 30px;
	max-width: 30px;
}
.timeDiv {
	width: 50px;
	max-width: 50px;
}
.border-box {
	border: solid;
	border-color: #d2d2d2;
	border-width: thin;
	margin: 1px 0;
	border-radius: 5px;
}
.card_heading {
	font-size: 13px;
	font-weight: 300;
}
.card_sub_heading {
	font-size: 11px;
	font-weight: normal;
}

.border-box:hover {
	border: solid 1px #333 !important;
	background: #f5f5f5 !important;
}
.email-header-row {
	background-color: blue;
	padding: 10px;
	color: #fff;
}
.email-header-links {
	display: flex;
	justify-content: flex-end;
}
.email-header-links i {
	padding-left: 15px;
	padding-right: 15px;
}
.file-upload {
	background-color: #ffffff;
	width: 600px;
	margin: 0 auto;
	padding: 20px;
}

#file-upload-btn {
	float: left;
	margin-top: -22px;
	font-size: 14px;
	border-radius: 2px;
}
.menu-box,
.menu-card {
	padding-right: 0;
}
.email-reader {
	padding-left: 0;
}

.email-menu-list .menus {
	border: 1px solid #adb5bd;
	margin: -1px;

	font-size: 12px;
}

.email-menu-list .menus .title,
.badge-2 {
	margin-top: 8px;
}

.email-menu-list .menus p {
	margin-top: 10px;
	margin-bottom: 8px;
	text-align: center;
}

.email-menu-list a {
	color: #919ca7;
	padding: 12px 5px;
	display: block;
	font-size: 15px;
}

.email-menu-list .badges {
	/* margin-top: 3px; */
	color: #fff;
	background-color: #000;
}

.badge-danger-lighten {
	color: #fa6767;
	background-color: rgba(250, 103, 103, 0.18);
}

.float-end {
	float: right !important;
}

.email-body {
	max-height: 100px;
	overflow-y: scroll;
}
/* .file-upload-btn:hover {

  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
} */

/* .file-upload-btn:active {

  transition: all .2s ease;
} */

/* .file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #eee;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #eee;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
} */
.uploader {
	clear: both;
	margin: auto 102px;
	width: 100%;
	max-width: 90%;
}
.uploader label {
	float: left;
	clear: both;
	width: 100%;
	padding: 1.8rem 2rem 10px 8px;
	text-align: center;
	background: #fff;
	border-radius: 7px;
	border: 3px dashed #eee;
	transition: all 0.2s ease;
	-webkit-user-select: none;
	user-select: none;
}
.uploader label:hover {
	border-color: #eee;
}
.uploader label.hover {
	border: 3px solid #eee;
	box-shadow: inset 0 0 0 6px #eee;
}
.uploader label.hover #start i.fa {
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
	opacity: 0.3;
}
.uploader #start {
	float: left;
	clear: both;
	width: 100%;
}
.uploader #start.hidden {
	display: none;
}
.uploader #start i.fa {
	font-size: 50px;
	margin-bottom: 1rem;
	transition: all 0.2s ease-in-out;
}
.uploader #response {
	float: left;
	clear: both;
	width: 100%;
}
.uploader #response.hidden {
	display: none;
}
.uploader #response #messages {
	margin-bottom: 0.5rem;
}
.uploader #file-image {
	display: inline;
	margin: 0 auto 0.5rem auto;
	width: auto;
	height: auto;
	max-width: 180px;
}
.uploader #file-image.hidden {
	display: none;
}
.uploader #notimage {
	display: block;
	float: left;
	clear: both;
	width: 100%;
}
.uploader #notimage.hidden {
	display: none;
}
.uploader progress,
.uploader .progress {
	display: inline;
	clear: both;
	margin: 0 auto;
	width: 100%;
	max-width: 180px;
	height: 8px;
	border: 0;
	border-radius: 4px;
	background-color: #eee;
	overflow: hidden;
}
.uploader .progress[value]::-webkit-progress-bar {
	border-radius: 4px;
	background-color: #eee;
}
.uploader .progress[value]::-webkit-progress-value {
	background: linear-gradient(to right, #393f90 0%, #454cad 50%);
	border-radius: 4px;
}
.uploader .progress[value]::-moz-progress-bar {
	background: linear-gradient(to right, #393f90 0%, #454cad 50%);
	border-radius: 4px;
}
.uploader input[type='file'] {
	display: none;
}
.uploader div {
	margin: 0 0 0.5rem 0;
	color: #5f6982;
}
.background-theme {
	background-color: #0a053d;
}

.font-12 {
	font-size: 12px;
}

.ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.background-ccc {
	background-color: #ccc;
}

.color-fff {
	color: #fff;
}

.line-height-30 {
	line-height: 30px;
}

.color-999 {
	color: #999;
}

.font-10 {
	font-size: 10px;
}

.color-theme {
	color: #0d47a1;
}

.color-theme {
	color: #162a4f;
}

.background-ccc {
	background-color: #ccc;
}

.border-radius-100 {
	border-radius: 100%;
}

.bold {
	font-weight: bold;
}

.attach_list_down {
	width: 20px;
	height: 20px;
	display: block;
	margin: auto;
	line-height: 18px;
}

.email_op {
	display: none;
	position: absolute;
	background-color: #fff;
	max-width: 400px;
	border: solid 1px #aeaeae;
	z-index: 2;
	font-size: 13px;
}
.reply_mail_icons {
	margin: 0px 5px;
}
.border_ccc {
	border: 1px solid #777;
	border-radius: 5px;
}
.mb-50 {
	margin-bottom: 50px;
}

.fs-5-noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

#water-mark-wrapper {
	height: 100% !important;
	position: '' !important;
	-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

#watermark-custom-wrapper {
	height: 100% !important;
	position: '' !important;
	-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

#root {
	height: 100% !important;
	/* -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    /* color-adjust: exact !important;                 Firefox 48 – 96 */
    /* print-color-adjust: exact !important;           Firefox 97+, Safari 15.4+ */ 
	
}

/* @media print{
	body {display:none;}
} */

/* Button Loader */
.lds-dual-ring {
	display: inline-block;
	width: 16px;
	height: 16px;
}

.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 3px solid #fff;
	border-color: #fff transparent #fff transparent;
	-webkit-animation: lds-dual-ring 1.2s linear infinite;
	        animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}



